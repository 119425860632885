.videoDiv {
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.videoDivVisible {
    opacity: 1;
    transform: scale(1);
}

.cameraControlsDiv {
    position: absolute;
    bottom: 0;
    margin: 8px;
    height: 40px;
    border: 1px solid #4F4F4F;
    border-radius: 8px;
    padding: 4px;
    background-color: #4F4F4F;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'MetropolisMedium', sans-serif;
    font-weight: 600;
    z-index: 100;

    img {
        margin-right: 8px;
        cursor: pointer;
        border-radius: 6px;
    }

    img:hover {
        background-color: rgba(235, 235, 245, 0.24);
    }

    .verticalLine {
        height: 80%;
        width: 12px;
        border-left: 1px solid #EBEBF5;
    }
}

.moreOptionsDropdown {
    position: absolute;
    width: 200px;
    background: var(--n-00);
    border: 1px solid #DADEE1;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 16px 16px -4px rgba(16, 24, 40, 0.08);
    border-radius: 12px;
    overflow: hidden;
    z-index: 10;
    right: 0;
    bottom: calc(100% + 8px); // Position above the button with 8px gap

  .dropdownItem {
    width: 100%;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: MetropolisMedium;
    font-size: 14px;
    color: #141414;
    cursor: pointer;

    img:hover {
        background-color: transparent;
    }

    &:hover {
        background-color: #F7F7F8;
    }
    &.danger {
        color: #FA6B75;
    }
  }
}
