.videoDivVisible {
    opacity: 1;
    transform: scale(1);
}
.nameBox {
    position: absolute;
    top: 0;
    left: 0;
    margin: 8px;
    height: 40px;
    padding: 4px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6), 0 0 2px rgba(0, 0, 0, .3);
    font-size: 14px;;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'MetropolisMedium', sans-serif;
    font-weight: 600;
    z-index: 2000;
}

.pinBox {
  position: absolute;
  bottom: 8px;
  right: 16px;
  border: 1px solid #5F5F63;
  border-radius: 4px;
  padding: 8px 4px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'MetropolisMedium', sans-serif;
  z-index: 2000;
  background-color: #4F4F4F;
  cursor: pointer;
  gap: 4px;
  border-radius: 4px;
}
.pinBox:hover {
  background-color: #5F5F63;
}
.muteIndicatorDiv {
    position: absolute;
    top:0;
    right: 0;
    margin: 8px;
    z-index: 2000;
    width: 28px; 
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .4);
  
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
.videoDiv {
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}
