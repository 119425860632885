.tooltipButton {
  display: flex;
  color: white;
  padding: 4px 6px;
  border: none;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #333;
}
.tooltipButtonText {
  font-family: MetropolisSemiBold;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
}
.tooltipButton:hover {
  background-color: #5f5f63;
}
.tooltipStyle {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: max-content;
  gap: 8px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.arrowStyle {
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #333;
}
